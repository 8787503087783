// import { sync } from 'vuex-pathify';

import { sync } from 'vuex-pathify';

export default {

  computed: {
    ...sync([
      'general/ongoingSeason', //
    ]),
  },

  watch: {
    $route() {
      // console.log(`app.watch - $route has been updated (name:${this.$route.name})`, this.$route.params);

      Bus.$emit('recaptcha', this.$route.name);

      if (this.$route.params.u_season) {
        // console.log(`app.watch - u_season has changed ${this.$route.params.u_season}`);
        // this.$store.dispatch('updateCurrentSeason', this.$route.params.u_season);
        this.$store.set('general/currentSeason', this.$route.params.u_season);
        this.loadNav();
      } else {
        // console.log(`app.mounted - u_season not set - defaulting to ${this.ongoingSeason}`);
        // this.$store.dispatch('updateCurrentSeason', this.ongoingSeason);
        this.$store.set('general/currentSeason', this.ongoingSeason);
        this.loadNav();
      }

      if (this.$route.params.u_region) {
        // console.log('app.watch.route.u_region changed', this.$route.params.u_region);
        this.$store.set('general/currentRegionId', this.$options.filters.extractId(this.$route.params.u_region));
      }

      if (this.$route.params.u_category) {
        // console.log('app.watch.route.u_category changed', this.$route.params.u_category);
        this.$store.set('general/currentCategoryId', this.$options.filters.extractId(this.$route.params.u_category));
      } else {
        this.$store.set('general/currentCategoryId', null);
      }

      if (this.$route.params.u_team) {
        // console.log('app.watch.route.u_team changed', this.$route.params.u_team);
        this.$store.set('general/currentTeamId', this.$options.filters.extractId(this.$route.params.u_team));
      }

      if (this.$route.params.u_tournament) {
        // console.log('app.watch.route.u_tournament changed', this.$route.params.u_tournament);
        this.$store.set('general/currentTournamentId', this.$options.filters.extractId(this.$route.params.u_tournament));
      }

      if (this.$route.params.u_division) {
        // console.log('app.watch.route.u_division changed', this.$route.params.u_division);
        this.$store.set('general/currentDivisionId', this.$options.filters.extractId(this.$route.params.u_division));
      }

      if (this.$route.params.u_game) {
        // console.log('app.watch.route.u_game changed', this.$route.params.u_game);
        this.$store.set('general/currentGameId', this.$options.filters.extractId(this.$route.params.u_game));
      }

      if (this.$route.params.u_user) {
        // console.log('app.watch.route.u_user changed', this.$route.params.u_user);
        this.$store.set('general/currentUserId', this.$options.filters.extractId(this.$route.params.u_user));
      }

      if (this.$route.params.u_supervisor) {
        // console.log('app.watch.route.u_supervisor changed', this.$route.params.u_supervisor);
        this.$store.set('general/currentSupervisorId', this.$options.filters.extractId(this.$route.params.u_supervisor));
      }

      Bus.$emit('route:changed');
    },
  },
};
