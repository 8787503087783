<template>
  <div class="tw-flex tw-flex-col tw-flex-items-center tw-flex-justify-center">
    <!-- |{{ selected }}| -->
    <multiselect
      v-model="selected"
      :searchable="true"
      :caret="false"
      :max-height="500"
      :placeholder="placeholder"
      :filter-results="false"
      :min-chars="1"
      :resolve-on-load="options.allowEmptySearch"
      :delay="200"
      :track-by="options.trackBy"
      :value-prop="options.valueProp"
      :label="options.label"
      :limit="options.limit"
      :options="async function(query) {
        return await asyncFindApi(query) // check JS block for implementation
      }"
      :object="true"
      :no-results-text="options.noResults"
      :no-options-text="options.noOptions"
      class="tw-text-white lg:tw-w-96"
      @select="onSelect"
    >
      <template #option="{ option }">
        <text-highlight
          :queries="[query]"
          highlight-class="tw-p-0 tw-font-bold tw-text-gray-100 tw-bg-transparent"
        >
          {{ option.n }}
        </text-highlight>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Vue from 'vue';
import TextHighlight from 'vue-text-highlight';

// new Vue ...
import multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';

Vue.component('TextHighlight', TextHighlight);

export default {
  components: {
    multiselect,
  },

  props: {
    api: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    customLabel: {
      type: Function,
      default: (option) => `${option.id}`,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },

  data() {
    return {
      query: '',
      selected: '',
      results: [],
      isLoading: false,

      defaultOptions: {
        valueProp: 'value',
        trackBy: 'n',
        label: 'n',
        limit: 20,
        // minimumSearchQuery: 3,
        // searchIconVisible: true,
        allowEmptySearch: false,
        noResults: '',
        noOptions: '',
      },
    };
  },

  computed: {
    options() {
      return Object.assign(this.defaultOptions, this.config);
    },
  },

  methods: {
    onSelect(option) {
      // console.log('onselect.option', option);
      this.$emit('onSelect', option);
    },

    openSearch() {
      this.results = [];

      // if (this.options.allowEmptySearch) {
      // this.asyncFindApi('', true);
      // }

      $('.multiselect input[type=text]').each((index, element) => {
        $(element).attr('data-lpignore', true); // prevent lastpass autocomplete
      });
    },

    async asyncFindApi(query) {
      // console.log(query);

      this.query = query;

      const response = await this.$http.post(this.api, {
        query,
      });

      return response.data;
    },
  },
};
</script>

<style>
.multiselect-placeholder {
  @apply tw-text-sm tw-text-gray-100 tw-whitespace-nowrap !important;
}

.multiselect-input {
  background: #3b3b3b;
  @apply tw-h-8 tw-border tw-border-gray-700 focus:tw-outline-none !important;
}

.multiselect-option.is-pointed {
  @apply tw-bg-gray-500 tw-no-underline !important;
}

.multiselect-option {
  @apply tw-text-sm tw-text-gray-100 tw-whitespace-nowrap !important;
}

.multiselect-options {
  @apply tw-border-0 !important;
  background: #3b3b3b !important;
}

.multiselect-options .multiselect-no-options {
  display: none;
}

.multiselect-spinner {
  @apply tw-bg-transparent !important;
}

</style>
