/* eslint-disable no-param-reassign */

import { make } from 'vuex-pathify';

import i18n from '@/lang/lang';

const lang = {
  namespaced: true,
  state: {
    lang: window.defaultLanguage,
    defaultLang: window.defaultLanguage,
    languages: [
      'fr',
      'en',
    ],
  },
  mutations: {},
  actions: {},
};

const mutations = {
  SET_LANG(state, payload) {
    i18n.locale = payload;
    state.lang = payload;
  },
};

const actions = {
  async switchLang({
    commit,
  }, payload) {
    if (payload in i18n.messages) {
      commit('SET_LANG', payload);
    } else {
      try {
        const langFile = `../../lang/locale/${payload}.json`;
        const res = await import(langFile);
        i18n.setLocaleMessage(payload, res.data);
        commit('SET_LANG', payload);
      } catch (e) {
        console.log(e);
      }
    }
  },
};

lang.mutations = Object.assign(make.mutations(lang.state), mutations);
lang.actions = Object.assign(lang.actions, actions);

export default lang;
