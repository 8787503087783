import { make } from 'vuex-pathify';

const filter = {
  namespaced: true,
  state: {
    dashboard: {
      activityLog: {
        season: null,
        action: null,
        type: null,
      },
      audit: {
        season: null,
        action: null,
        type: null,
      },
    },

    categoryId: '',
    regionId: '',

    games: {
      completedGamesOnly: 0,
    },

    video: {
      sorting: 'most-recent',
      searchCount: 0,
      selectedTags: [],
      selectedTagsForRequest: '',
    },
  },
  mutations: {},
  actions: {},
};

filter.mutations = make.mutations(filter.state);

export default filter;
