import Vapi from 'vuex-rest-api';

const stats = new Vapi({
  state: {
    stats: {
      members: 0,
      topics: 0,
      posts: 0,
    },
  },
})
  .get({
    action: 'getStats',
    property: 'stats',
    path: () => '/api/v1/site/stats',
  })
  .getStore();

export default stats;
