import {
  Model,
} from '@vuex-orm/core';

import Message from '@/vuex/orm/models/Message';

export default class extends Model {
  static entity = 'chatroom'

  static fields() {
    return {
      id: this.number(0),

      type: this.string('').nullable(),
      label: this.string('').nullable(),

      nbUsers: this.number(0).nullable(),
      nbMessages: this.number(0).nullable(),

      lastMessage: this.attr({}).nullable(),
      user: this.attr({}).nullable(),

      lastMessageAt: this.string('').nullable(),

      messages: this.hasMany(Message, 'chatroomId'),
    };
  }
}
