import { make } from 'vuex-pathify';

const advertising = {
  namespaced: true,
  state: {
    ads: [],
    initialized: false,
  },
  mutations: {},
  actions: {},
};

const mutations = {};

const actions = {};

advertising.mutations = Object.assign(make.mutations(advertising.state), mutations);
advertising.actions = Object.assign(advertising.actions, actions);

export default advertising;
