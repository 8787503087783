/* eslint-disable no-param-reassign */
import Vapi from 'vuex-rest-api';

const nav = new Vapi({
  state: {
    nav: {},
  },
})
  .get({
    action: 'getNav',
    property: 'nav',
    path: ({ season }) => `/api/v1/nav?season=${season}`,
    onSuccess: (state, payload) => {
      // console.log('Nav.getNav', payload.data);
      state.nav[payload.data.currentSeason.slug] = payload.data;
    },
  })
  .getStore();

export default nav;
