import Vapi from 'vuex-rest-api';

const video = new Vapi({
  state: {
    video: {
      vid: 0,
    },
  },
})
  .get({
    action: 'getVideo',
    property: 'video',
    path: ({ lang, vid }) => `/api/v1/videos/${vid}?lang=${lang}`,
  })
  .getStore();

export default video;
