const meta = {
  auth: {
    roles: 'admin',
    forbiddenRedirect: '/admin/403',
  },
};

export default [
  {
    path: '/en/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/Page-Admin'),
    meta,
  },
  {
    path: '/en/admin/ads',
    name: 'admin-ads',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/Page-Ads'),
    meta,
  },
  {
    path: '/en/admin/members',
    name: 'admin-members',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/Page-Members'),
    meta,
  },
  {
    path: '/en/admin/activity',
    name: 'admin-activity',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/Page-Activity'),
    meta,
  },
  {
    path: '/en/admin/analytics',
    name: 'admin-analytics',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/Page-Analytics'),
    meta,
  },
  {
    path: '/en/admin/stats',
    name: 'admin-stats',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/Page-Stats'),
    meta,
  },
  {
    path: '/en/admin/charts',
    name: 'admin-charts',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/Page-Charts'),
    meta,
  },
  {
    path: '/en/admin/push-demo',
    name: 'admin-push-demo',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/playground/Page-PushDemo'),
    meta,
  },
  {
    path: '/en/admin/speech',
    name: 'admin-speech',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/playground/Page-Speech'),
    meta,
  },
  {
    path: '/en/admin/playground/tailwind',
    name: 'admin-playground-tailwind',
    component: () => import(/* webpackChunkName: "page--admin" */ '@/pages/admin/playground/Page-Tailwind'),
    meta,
  },
  {
    path: '/en/admin/403',
    name: 'admin-403',
    component: () => import(/* webpackChunkName: "page--common" */ '@/pages/admin/Page-403'),
  },
];
