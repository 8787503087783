/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import { make } from 'vuex-pathify';
import i18n from '@/lang/lang';

const meta = {
  namespaced: true,
  state: {
    title: process.env.VUE_APP_SITE_TITLE,
    description: process.env.VUE_APP_SITE_TITLE,
    // url: process.env.VUE_APP_SITE_URL,
    // canonical: process.env.VUE_APP_SITE_URL,
    head: {},
  },
  mutations: {},
  actions: {},
};

const mutations = {
  updateMeta: (state, payload) => {
    // console.log('updateMeta', payload);

    if (typeof payload.title === 'undefined' || payload.title === '') {
      state.title = process.env.VUE_APP_SITE_TITLE;
    } else {
      state.title = `${payload.title}`;
    }

    if (typeof payload.description === 'undefined' || payload.description === '') {
      const extractedLang = window.location.pathname.split(/[/]+/g)[1];
      const lang = ['fr', 'en'].indexOf(extractedLang) > -1 ? extractedLang : 'fr';
      state.description = i18n.t('JSON.meta-default-description', lang).replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());
      // console.log(`using default [${lang}] meta description`, state.description);
    } else {
      state.description = `${payload.description}`;
    }

    state.head = {};

    if (typeof payload.link !== 'undefined' && typeof payload.link.alternate !== 'undefined') {
      Object.keys(payload.link.alternate).forEach((lang) => {
        state.head[`link[hreflang="${lang}"]`] = { href: `${process.env.VUE_APP_SITE_URL}${payload.link.alternate[lang]}` };
      });
    }

    // state.url = `${process.env.VUE_APP_SITE_URL}${payload.path}`;
  },
};

const actions = {
  updateMeta({ commit }, payload) {
    commit('updateMeta', payload);
  },
};

meta.mutations = Object.assign(make.mutations(meta.state), mutations);
meta.actions = Object.assign(meta.actions, actions);

export default meta;
