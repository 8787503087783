import { make } from 'vuex-pathify';

const device = {
  namespaced: true,
  state: {
    device: {
      mobile: null,
      os: null,
      userAgent: null,
    },
    platform: 'web',
    windowWidth: 0,
    windowHeight: 0,
  },
  mutations: {},
  actions: {},
};

device.mutations = make.mutations(device.state);

export default device;
