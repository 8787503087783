import Vue from 'vue';
import Vuex from 'vuex';

import VuexORM from '@vuex-orm/core';

import Chatroom from './models/Chatroom';
import Message from './models/Message';

import Tag from './models/Tag';
import Video from './models/Video';
import VideoTag from './models/VideoTag';

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(Chatroom);
database.register(Message);
database.register(Tag);
database.register(Video);
database.register(VideoTag);

export default database;
