/* eslint-disable global-require */

import Vue from 'vue';
import VueTimeago from 'vue-timeago';

import customConverter from '../toNowConverter';

Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'en', // default locale
  locales: {
    fr: require('date-fns/locale/fr'),
    es: require('date-fns/locale/es'),
  },
  converter: customConverter,
});
