<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-p-2 tw-m-2 tw-text-lg tw-font-semibold tw-text-white tw-rounded-lg tw-bg-poc-red-500">
    <div v-if="lang === 'en'">
      <div>There aren't any games yet for the {{ currentSeason }} season.</div>
      <div>
        Click <span
          class="tw-underline tw-cursor-pointer"
          @click="$root.$emit('bv::show::modal', 'super')"
        >here</span> to add a game
      </div>
      <div>
        Click <span
          class="tw-underline tw-cursor-pointer"
          @click="previousSeason()"
        >here</span> to go to the previous season
      </div>
    </div>
    <div v-else-if="lang === 'fr'">
      <div>Il n'y a pas encore de parties pour la saison {{ currentSeason }}.</div>
      <div>
        Appuyez <span
          class="tw-underline tw-cursor-pointer"
          @click="$root.$emit('bv::show::modal', 'super')"
        >ici</span> pour entrer des parties
      </div>
      <div>
        Appuyez <span
          class="tw-underline tw-cursor-pointer"
          @click="previousSeason()"
        >ici</span> pour aller à la saison précédente
      </div>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  computed: {
    ...sync([
      'lang/lang', //
      'general/currentSeason', //
    ]),
  },

  methods: {
    previousSeason() {
      Bus.$emit('season_down');
    },
  },
};
</script>
