import { make } from 'vuex-pathify';

const orm = {
  namespaced: true,
  state: {
    videosLoaded: false,
  },
  mutations: {},
  actions: {},
};

orm.mutations = make.mutations(orm.state);

export default orm;
