import Vapi from 'vuex-rest-api';

const dashboard = new Vapi({
  state: {
    dashboard: {
      user: {
        id: 0,
        username: '',
      },
      supervisor: {
        status: '',
        leagues: [],
        tournaments: [],
      },
    },
  },
})
  .get({
    action: 'getDashboard',
    property: 'dashboard',
    path: ({ lang }) => `/api/v1/dashboard?lang=${lang}`,
  })
  .getStore();

export default dashboard;
