import routeHelpers from '@/routes/routeHelpers';

const routeRegex = {
  lang: ':u_lang(en|fr)', // matches /xx
  season: ':u_season(\\d{4}-\\d{4})', // matches /2014-2015
  region: ':u_region(r-[0-9]+-[0-9A-Za-z-]+)',
  category: ':u_category(c-[0-9]+-[0-9A-Za-z-]+)',
  division: ':u_division(d-[0-9]+-[0-9A-Za-z-]+)',
  team: ':u_team(e-[0-9]+-[0-9A-Za-z-]+)',
  team1: ':u_team_1(e1-[0-9]+-[0-9A-Za-z-]+)',
  team2: ':u_team_2(e2-[0-9]+-[0-9A-Za-z-]+)',
  game: ':u_game(g-[0-9]+)',
  tournament: ':u_tournament(t-[0-9]+-[0-9A-Za-z-]+)',
};

// console.log(`Browser language is: ${browserLanguage}. Default language is: ${defaultLanguage}`);

export default [

  {
    path: `/en/${routeRegex.season}`,
    name: 'season',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}`),
    component: () => import(/* webpackChunkName: "page--Season" */ '@/pages/season/Page-Season'),
  },
  {
    path: `/en/${routeRegex.season}/latest-results`,
    name: 'latest-results',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s`, ['latest-results']),
    component: () => import(/* webpackChunkName: "page--Season" */ '@/pages/season/Page-LatestResults'),
  },
  {
    path: `/en/${routeRegex.season}/leaderboard`,
    name: 'leaderboard',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s`, ['leaderboard']),
    component: () => import(/* webpackChunkName: "page--Season" */ '@/pages/season/Page-Leaderboard'),
  },
  {
    path: `/en/${routeRegex.season}/teams`,
    name: 'teams',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s`, ['teams']),
    component: () => import(/* webpackChunkName: "page--Teams" */ '@/pages/season/Page-Teams'),
  },
  {
    path: `/en/${routeRegex.season}/teams/${routeRegex.category}`,
    name: 'teams',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s/${routeRegex.category}`, ['teams']),
    component: () => import(/* webpackChunkName: "page--Teams" */ '@/pages/season/Page-Teams'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}/${routeRegex.team}`,
    name: 'team',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}/${routeRegex.team}`),
    component: () => import(/* webpackChunkName: "page--Team" */ '@/pages/season/Page-Team'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}/${routeRegex.team}/stats`,
    name: 'team-stats',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}/${routeRegex.team}/stats`),
    component: () => import(/* webpackChunkName: "page--TeamStats" */ '@/pages/season/Page-TeamStats'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.team1}/${routeRegex.team2}`,
    name: 'rivalry',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.team1}/${routeRegex.team2}`),
    component: () => import(/* webpackChunkName: "page--TeamRivalry" */ '@/pages/season/Page-TeamRivalry'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.game}`,
    name: 'game',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.game}`),
    component: () => import(/* webpackChunkName: "page--Game" */ '@/pages/season/Page-Game'),
  },
  {
    path: `/en/${routeRegex.season}/tournaments`,
    name: 'tournaments',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s`, ['tournaments']),
    component: () => import(/* webpackChunkName: "page--Tournaments" */ '@/pages/season/Page-Tournaments'),
  },
  {
    path: `/en/${routeRegex.season}/tournaments/${routeRegex.category}`,
    name: 'tournaments',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s/${routeRegex.category}`, ['tournaments']),
    component: () => import(/* webpackChunkName: "page--Tournaments" */ '@/pages/season/Page-Tournaments'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.tournament}`,
    name: 'tournament',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.tournament}`),
    component: () => import(/* webpackChunkName: "page--Tournament" */ '@/pages/season/Page-Tournament'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.tournament}/${routeRegex.category}`,
    name: 'tournament',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.tournament}/${routeRegex.category}`),
    component: () => import(/* webpackChunkName: "page--Tournament" */ '@/pages/season/Page-Tournament'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.category}`,
    name: 'category',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.category}`),
    component: () => import(/* webpackChunkName: "page--Category" */ '@/pages/season/Page-Category'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.region}`,
    name: 'region',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.region}`),
    component: () => import(/* webpackChunkName: "page--Region" */ '@/pages/season/Page-Region'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}`,
    name: 'league',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}`),
    component: () => import(/* webpackChunkName: "page--League" */ '@/pages/season/Page-League'),
  },
  {
    path: `/en/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}/${routeRegex.division}`,
    name: 'league-division',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/${routeRegex.category}/${routeRegex.region}/${routeRegex.division}`),
    component: () => import(/* webpackChunkName: "page--League" */ '@/pages/season/Page-League'),
  },
  {
    path: `/en/${routeRegex.season}/leagues`,
    name: 'leagues',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s`, ['leagues']),
    component: () => import(/* webpackChunkName: "page--Leagues" */ '@/pages/season/Page-Leagues'),
  },
  {
    path: `/en/${routeRegex.season}/leagues/${routeRegex.category}`,
    name: 'leagues',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/leagues/${routeRegex.category}`, ['leagues']),
    component: () => import(/* webpackChunkName: "page--Leagues" */ '@/pages/season/Page-Leagues'),
  },
  {
    path: `/en/${routeRegex.season}/map`,
    name: 'map',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s`, ['map']),
    component: () => import(/* webpackChunkName: "page--Map" */ '@/pages/season/Page-Map'),
  },
  {
    path: `/en/${routeRegex.season}/map/${routeRegex.category}`,
    name: 'map',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s/${routeRegex.category}`, ['map']),
    component: () => import(/* webpackChunkName: "page--Map" */ '@/pages/season/Page-Map'),
  },
  {
    path: `/en/${routeRegex.season}/supervisors`,
    name: 'supervisors',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s`, ['supervisors']),
    component: () => import(/* webpackChunkName: "page--Supervisors" */ '@/pages/season/Page-Supervisors'),
  },
  {
    path: `/en/${routeRegex.season}/supervisors/${routeRegex.category}`,
    name: 'supervisors',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.season}/%s/${routeRegex.category}`, ['supervisors']),
    component: () => import(/* webpackChunkName: "page--Supervisors" */ '@/pages/season/Page-Supervisors'),
  },
];
