import Vapi from 'vuex-rest-api';

const search = new Vapi({
  state: {
    searchResults: [],
    teams: [],
    tournaments: [],
    categories: [],
    regions: [],
    divisions: [],
    leagues: [],
    locations: [],
    places: [],
  },
})
  .get({
    action: 'getSearchResults',
    property: 'searchResults',
    path: ({ lang, sid, query }) => `/api/v1/search?lang=${lang}&sid=${sid}&query=${query}`,
  })
  .get({
    action: 'getTeams',
    property: 'teams',
    path: ({ sid, lid, query }) => `/api/v1/search/teams?sid=${sid}&lid=${lid}&query=${query}`,
  })
  .get({
    action: 'getTournaments',
    property: 'tournaments',
    path: ({ lang, sid, query }) => `/api/v1/search/tournaments?lang=${lang}&sid=${sid}&query=${query}`,
  })
  .get({
    action: 'getTournamentTeams',
    property: 'teams',
    path: ({
      sid, tid, query,
    }) => `/api/v1/search/tournaments/${tid}/teams?sid=${sid}&query=${query}`,
  })
  .get({
    action: 'getCategories',
    property: 'categories',
    path: ({ sid, query }) => `/api/v1/search/categories?sid=${sid}&query=${query}`,
  })
  .get({
    action: 'getRegions',
    property: 'regions',
    path: ({ sid, cid, query }) => `/api/v1/search/regions?sid=${sid}&cid=${cid}&query=${query}`,
  })
  .get({
    action: 'getDivisions',
    property: 'divisions',
    path: ({
      sid, cid, rid, query,
    }) => `/api/v1/search/divisions?sid=${sid}&cid=${cid}&rid=${rid}&query=${query}`,
  })
  .get({
    action: 'getLeagues',
    property: 'leagues',
    path: ({
      sid, query,
    }) => `/api/v1/search/leagues?sid=${sid}&query=${query}`,
  })
  .get({
    action: 'getLocations',
    property: 'locations',
    path: ({ query }) => `/api/v1/search/locations?query=${query}`,
  })
  .get({
    action: 'getPlaces',
    property: 'places',
    path: ({ query }) => `/api/v1/search/places?query=${query}`,
  })
  .getStore();

export default search;
