export default {

  data() {
    return {
      consoleLogEnabled: false,
      consoleLogMethod: null,
    };
  },

  computed: {
    isAuthenticated() {
      // console.log('main.computed.isAuthenticated', this.$auth.check());
      return this.$auth.check();
    },
  },

  watch: {
    isAuthenticated() {
      this.toggleConsoleLog();
    },
  },

  methods: {
    toggleConsoleLog() {
      // Disable logging if not on dev
      if (process.env.NODE_ENV === 'development') {
        this.consoleLogEnabled = true;
      } else if (this.$auth.check() && (this.$auth.user().id === 1 || this.$auth.impersonating())) {
        console.log(`Welcome user#${this.$auth.user().id}`);
        this.consoleLogEnabled = true;
      }

      // if (this.consoleLogEnabled) {
      //   console.log = this.consoleLogMethod;
      //   console.log(`ENV => ${process.env.NODE_ENV} - enabling console messages (user#${this.$auth.user().id})!`);
      // } else {
      //   console.log(`ENV => ${process.env.NODE_ENV} - disabling console messages (user#${this.$auth.user().id})!`);
      //   console.log = () => {};
      // }
    },
  },

  mounted() {
    this.consoleLogMethod = console.log;
    this.toggleConsoleLog();
  },

};
