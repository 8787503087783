import { make } from 'vuex-pathify';

const cache = {
  namespaced: true,
  state: {
    timestamp: 0,
  },
  mutations: {},
  actions: {},
};

const mutations = {};

const actions = {};

cache.mutations = Object.assign(make.mutations(cache.state), mutations);
cache.actions = Object.assign(cache.actions, actions);

export default cache;
