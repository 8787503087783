<template>
  <a
    class="tour"
    @click="triggerTour()"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    tour: {
      type: String,
      default: () => '',
    },
  },

  methods: {
    triggerTour() {
      Bus.$emit('local_tour', this.tour);
    },
  },
};
</script>

<style scoped>
a.tour {
  cursor: pointer;
  color: #d70000;
  font-weight: 600;
}
</style>
