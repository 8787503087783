import Vapi from 'vuex-rest-api';

const game = new Vapi({
  state: {
    game: {
      id: 0,
      tournament: {
        id: 0,
        name: '',
      },
    },
    liveScores: {
      games: [],
      dates: [],
    },
  },
})
  .get({
    action: 'getGame',
    property: 'game',
    path: ({ lang, gid }) => `/api/v1/games/${gid}?lang=${lang}`,
  })
  .get({
    action: 'getLiveScores',
    property: 'liveScores',
    path: ({ lang, sid }) => `/api/v1/games/scores?lang=${lang}&sid=${sid}`,
  })
  .getStore();

export default game;
