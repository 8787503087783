import Vapi from 'vuex-rest-api';

const messages = new Vapi({
  state: {
    welcomeMessage: '',
  },
})
  .get({
    action: 'getWelcomeMessage',
    property: 'welcomeMessage',
    path: ({ lang }) => `/api/v1/site/welcome-message?lang=${lang}`,
  })
  .getStore();

export default messages;
