import Vapi from 'vuex-rest-api';

const city = new Vapi({
  state: {
    city: {
      id: 0,
      name: '',
    },

    cities: {
      categories: [

      ],
      count: 0,
    },
  },
})
  .get({
    action: 'getCity',
    property: 'city',
    path: ({ lang, ciid }) => `/api/v1/cities/${ciid}?lang=${lang}`,
  })
  .get({
    action: 'getCities',
    property: 'city',
    path: ({ lang }) => `/api/v1/cities?lang=${lang}`,
  })
  .getStore();

export default city;
