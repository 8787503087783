<template>
  <a
    class="pop"
    @click="triggerPop()"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: () => '',
    },
  },

  methods: {
    triggerPop() {
      Bus.$emit('ok-box:show', {
        title: this.$t('JSON.info'),
        body: this.content,
        okVariant: 'success',
      });
    },
  },
};
</script>

<style scoped>
a.pop {
  cursor: pointer;
  color: #d70000;
  font-weight: 600;
}
</style>
