import Vue from 'vue';
import Router from 'vue-router';

import _ from 'lodash';

import GeneralRoutes from './routes/general';
import SeasonRoutes from './routes/season';
import AuthRoutes from './routes/auth';
import AdminRoutes from './routes/admin';
import UtilityRoutes from './routes/utility';
import RedirectRoutes from './routes/redirect';
import TestRoutes from './routes/test';
import PlayRoutes from './routes/playground';

import store from './vuex/store';

Vue.use(Router);

/* THIS IS PURPOSEFULLY COMMENTED OUT, DO NOT REMOVE */

// this.$t('JSON.ROUTE_about')
// this.$t('JSON.ROUTE_blog')
// this.$t('JSON.ROUTE_changelog')
// this.$t('JSON.ROUTE_chat')
// this.$t('JSON.ROUTE_cities')
// this.$t('JSON.ROUTE_contact')
// this.$t('JSON.ROUTE_dashboard')
// this.$t('JSON.ROUTE_faq')
// this.$t('JSON.ROUTE_features')
// this.$t('JSON.ROUTE_forgotten-password')
// this.$t('JSON.ROUTE_forgotten-username')
// this.$t('JSON.ROUTE_home')
// this.$t('JSON.ROUTE_latest-results')
// this.$t('JSON.ROUTE_leaderboard')
// this.$t('JSON.ROUTE_leagues')
// this.$t('JSON.ROUTE_login')
// this.$t('JSON.ROUTE_map')
// this.$t('JSON.ROUTE_organizations')
// this.$t('JSON.ROUTE_participate')
// this.$t('JSON.ROUTE_playlist')
// this.$t('JSON.ROUTE_privacy-policy')
// this.$t('JSON.ROUTE_register')
// this.$t('JSON.ROUTE_statistics')
// this.$t('JSON.ROUTE_supervisor')
// this.$t('JSON.ROUTE_supervisors')
// this.$t('JSON.ROUTE_surveys')
// this.$t('JSON.ROUTE_teams')
// this.$t('JSON.ROUTE_terms-of-use')
// this.$t('JSON.ROUTE_tournaments')
// this.$t('JSON.ROUTE_video')
// this.$t('JSON.ROUTE_videos')

const routes = GeneralRoutes.concat(SeasonRoutes, AuthRoutes, AdminRoutes, UtilityRoutes, RedirectRoutes, TestRoutes, PlayRoutes);
// const routes = GeneralRoutes.concat(AuthRoutes, TestRoutes);

const router = new Router({
  mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  scrollBehavior(to, from, savedPosition) {
    // console.log('scrollBehavior.to', to);
    // console.log('scrollBehavior.from', from);
    // console.log('scrollBehavior.savedPosition', savedPosition);
    if (savedPosition) {
      return savedPosition;
    }

    if (to.name === from.name) {
      return {};
    }

    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log('beforeEach.to', to);
  if (to.name !== 'redirect') {
    if (to.path !== from.path) {
      if (to.meta.title) {
        // console.log('beforeEach.to.meta', to.meta);
        const meta = _.pick(to.meta, ['title', 'description']);
        // console.log('router.meta', meta);
        // meta.path = to.path === '/landing' ? '/' : to.path;
        store.dispatch('meta/updateMeta', meta);
      }
      // else {
      //   // const metaUrl = `${Vue.prototype.$metas}/metas${to.path}/meta.json`;
      //   const metaUrl = `/api/v1/meta?s=${to.path}`;
      //   axios
      //     .get(metaUrl)
      //     .then((response) => {
      //     // console.log('meta.response', response.data);
      //       store.dispatch('meta/updateMeta', response.data);
      //     }).catch((err) => {
      //       console.log('metas.err', err);
      //     });
      // }
    }
  }

  if (to.name === '404') {
    console.log('beforeEach.to.404', to);

    const regEx = new RegExp(`^/[a-z]{2}${to.path}$`);

    routes.forEach(route => {
      if (route.alias) {
        route.alias.forEach(alias => {
          if (regEx.test(alias)) {
            console.log(`redirecting ${to.path} to ${alias}`);
            next({ path: alias });
          }
        });
      }
    });
  }

  if (document.body.className.match('modal-open')) {
    console.log('aborting navigation and closing modals instead');
    Bus.$emit('hide_all_modals');
    next(false);
  } else {
    next();
  }
});

// router.beforeResolve((to, from, next) => {
//   /* must call `next` */
//   console.log('beforeResolve', to);

//   next();
// });

// router.beforeLeave((to, from, next) => {
//   console.log('beforeLeave');
//   // not executed when route stays the same, but only params change
//   next(false);
// });

export default router;
