/* eslint-disable global-require */
/* eslint-disable no-alert */

import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth';

import router from './router';

import i18n from '@/lang/lang';

axios.defaults.baseURL = Vue.prototype.$api;

axios.interceptors.response.use(
  response => response,
  error => {
    // console.log('axios.error', error.response.data);
    if (error.response.data === 'maintenance-mode') {
      alert(`Classement POC ${i18n.t('JSON.is-being-updated-and-will-be-back-shortly')}`);
    }
    return Promise.reject(error);
  },
);

window.axios = axios; // important for vue-tables-2

Vue.router = router; // important for vue-auth

Vue.use(VueAxios, axios);
Vue.use(VueAuth, {
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),

  loginData: {
    url: '/api/v1/auth/login',
    method: 'POST',
    redirect: false,
    fetchUser: false,
  },

  registerData: {
    url: '/api/v1/auth/register',
    method: 'POST',
    redirect: false,
    fetchUser: false,
  },

  logoutData: {
    url: '/api/v1/auth/logout',
    method: 'GET',
    redirect: false,
    makeRequest: true,
  },

  refreshData: {
    url: '/api/v1/auth/refresh',
    method: 'GET',
    enabled: false,
    interval: 30,
    error() {
      window.localStorage.clear();
      window.location = `${URL}login`;
    },
  },

  fetchData: {
    url: '/api/v1/auth/user',
    method: 'GET',
    enabled: true,
  },

  // authRedirect: {
  //   path: '/dashboard',
  // },

  // forbiddenRedirect: {
  //   path: '/403',
  // },

  // notFoundRedirect: {
  //   path: '/404',
  // },

  impersonateData: {
    url: '/api/v1/auth/impersonate',
    method: 'POST',
    // redirect: i18n.t('JSON.ROUTE_dashboard'),
  },

  unimpersonateData: {
    url: '/api/v1/auth/unimpersonate',
    method: 'POST',
    redirect: false,
    makeRequest: false,
  },
});
