import Vapi from 'vuex-rest-api';

const organization = new Vapi({
  state: {
    organization: {
      id: 0,
      name: '',
    },

    organizations: {
      categories: [

      ],
      count: 0,
    },
  },
})
  .get({
    action: 'getOrganization',
    property: 'organization',
    path: ({ lang, oid }) => `/api/v1/organizations/${oid}?lang=${lang}`,
  })
  .get({
    action: 'getOrganizations',
    property: 'organization',
    path: ({ lang }) => `/api/v1/organizations?lang=${lang}`,
  })
  .getStore();

export default organization;
