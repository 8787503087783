import Vapi from 'vuex-rest-api';

const division = new Vapi({
  state: {
    division: {
      id: 0,
      name: '',
    },
  },
})
  .get({
    action: 'getDivision',
    property: 'division',
    path: ({ lang, did }) => `/api/v1/divisions/${did}?lang=${lang}`,
  })
  .getStore();

export default division;
