import { make } from 'vuex-pathify';

const adminFilter = {
  namespaced: true,
  state: {
    logs: {
      activityLog: {
        season: null,
        action: null,
        type: null,
      },
      audit: {
        season: null,
        action: null,
        type: null,
      },
    },
    member: {
      banned: false,
      disabled: false,
      season: null,
    },
  },
  mutations: {},
  actions: {},
};

adminFilter.mutations = make.mutations(adminFilter.state);

export default adminFilter;
