import { make } from 'vuex-pathify';

const captcha = {
  namespaced: true,
  state: {
    score: 0,
    action: '',
    token: '',
  },
  mutations: {},
  actions: {},
};

captcha.mutations = make.mutations(captcha.state);

export default captcha;
