import { make } from 'vuex-pathify';

const app = {
  namespaced: true,
  state: {
    fcm: {
      token: false,
    },
    network: {
      state: {
        offline: false,
      },
    },
  },
  mutations: {},
  actions: {},
};

const mutations = {};

app.mutations = Object.assign(make.mutations(app.state), mutations);

export default app;
