<template>
  <router-link :to="link">
    <slot />
  </router-link>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  props: {
    season: {
      type: Boolean,
      default: () => false,
    },
    route: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    link() {
      let route;

      if (this.season) {
        if (this.route) {
          route = this.$i18n.t(`JSON.ROUTE_${this.route}`);
          return this.navigate(`/${this.currentSeason}/${route}`);
        }
        return this.navigate(`/${this.currentSeason}`);
      }

      route = this.$i18n.t(`JSON.ROUTE_${this.route}`);
      return this.navigate(`/${route}`);
    },

    ...sync([
      'general/currentSeason', //
      'general/ongoingSeason',
    ]),
  },
};
</script>
