import routeHelpers from '@/routes/routeHelpers';

const routeRegex = {
  lang: ':u_lang(en|fr)', // matches /xx
  user: ':u_user(u-[0-9]+-[0-9A-Za-z-_]+)',
  supervisor: ':u_supervisor(s-[0-9]+-[0-9A-Za-z-_]+)',
  city: ':u_city(v-[0-9]+-[0-9A-Za-z-]+)',
  organization: ':u_organization(o-[0-9]+-[0-9A-Za-z-]+)',
  blog: ':u_blog(b-[0-9A-Za-z-]+)',
  page: ':u_page([0-9]+)',
};

// console.log(`Browser language is: ${browserLanguage}. Default language is: ${defaultLanguage}`);

export default [
  {
    path: '/en/home',
    name: 'home',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['home']),
    component: () => import(/* webpackChunkName: "page--About" */ '@/pages/general/Page-Home'),
  },
  {
    path: '/en/about',
    name: 'about',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['about']),
    component: () => import(/* webpackChunkName: "page--About" */ '@/pages/general/Page-About'),
  },
  {
    path: '/en/faq',
    name: 'faq',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['faq']),
    component: () => import(/* webpackChunkName: "page--Faq" */ '@/pages/general/Page-Faq'),
  },
  {
    path: '/en/participate',
    name: 'participate',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['participate']),
    component: () => import(/* webpackChunkName: "page--Faq" */ '@/pages/general/Page-Participate'),
  },
  {
    path: '/en/contact',
    name: 'contact',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['contact']),
    component: () => import(/* webpackChunkName: "page--Contact" */ '@/pages/general/Page-Contact'),
  },
  {
    path: '/en/statistics',
    name: 'statistics',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['statistics']),
    component: () => import(/* webpackChunkName: "page--Stats" */ '@/pages/general/Page-Stats'),
  },
  {
    path: '/en/changelog',
    name: 'changelog',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['changelog']),
    component: () => import(/* webpackChunkName: "page--ChangeLog" */ '@/pages/general/Page-ChangeLog'),
  },
  {
    path: '/en/instructions',
    name: 'instructions',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['instructions']),
    component: () => import(/* webpackChunkName: "page--Instructions" */ '@/pages/general/Page-Instructions'),
  },
  {
    path: '/en/features',
    name: 'features',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['features']),
    component: () => import(/* webpackChunkName: "page--Features" */ '@/pages/general/Page-Features'),
  },
  {
    path: '/en/surveys',
    name: 'surveys',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['surveys']),
    component: () => import(/* webpackChunkName: "page--About" */ '@/pages/general/Page-Surveys'),
  },
  {
    path: '/en/terms-of-use',
    name: 'terms-of-use',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['terms-of-use']),
    component: () => import(/* webpackChunkName: "page--TermsOfUse" */ '@/pages/general/Page-TermsOfUse'),
  },
  {
    path: '/en/privacy-policy',
    name: 'privacy-policy',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['privacy-policy']),
    component: () => import(/* webpackChunkName: "page--PrivacyPolicy" */ '@/pages/general/Page-PrivacyPolicy'),
  },
  {
    path: `/en/${routeRegex.supervisor}`,
    name: 'supervisor',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.supervisor}`),
    component: () => import(/* webpackChunkName: "page--Supervisor" */ '@/pages/general/Page-Supervisor'),
  },
  {
    path: `/en/${routeRegex.user}`,
    name: 'user',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.user}`),
    component: () => import(/* webpackChunkName: "page--User" */ '@/pages/general/Page-User'),
  },
  {
    path: `/en/${routeRegex.city}`,
    name: 'city',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.city}`),
    component: () => import(/* webpackChunkName: "page--City" */ '@/pages/general/Page-City'),
  },
  {
    path: '/en/cities',
    name: 'cities',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['cities']),
    component: () => import(/* webpackChunkName: "page--Cities" */ '@/pages/general/Page-Cities'),
  },
  {
    path: `/en/${routeRegex.organization}`,
    name: 'organization',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.organization}`),
    component: () => import(/* webpackChunkName: "page--Organization" */ '@/pages/general/Page-Organization'),
  },
  {
    path: '/en/organizations',
    name: 'organizations',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['organizations']),
    component: () => import(/* webpackChunkName: "page--Organizations" */ '@/pages/general/Page-Organizations'),
  },
  {
    path: '/en/timeline',
    name: 'timeline',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['timeline']),
    component: () => import(/* webpackChunkName: "page--Timeline" */ '@/pages/general/Page-Timeline'),
  },
  {
    path: '/en/blog',
    name: 'blog-index',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['blog']),
    component: () => import(/* webpackChunkName: "page--Blog" */ '@/pages/general/Page-BlogIndex'),
  },
  {
    path: `/en/${routeRegex.blog}`,
    name: 'blog',
    alias: routeHelpers.generateAliasesForRoute(`/${routeRegex.blog}`),
    component: () => import(/* webpackChunkName: "page--Blog" */ '@/pages/general/Page-Blog'),
  },

  {
    path: '/en/videos',
    name: 'videos',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['videos']),
    component: () => import(/* webpackChunkName: "page--Videos" */ '@/pages/general/Page-Videos'),
  },
  {
    path: '/en/videos/page/:page',
    name: 'videos',
    alias: routeHelpers.generateAliasesForRoute('/%s/page/:page', ['videos']),
    component: () => import(/* webpackChunkName: "page--Videos" */ '@/pages/general/Page-Videos'),
  },
  {
    path: '/en/video/:vid',
    name: 'video',
    alias: routeHelpers.generateAliasesForRoute('/%s/:vid', ['video']),
    component: () => import(/* webpackChunkName: "page--Video" */ '@/pages/general/Page-Video'),
  },
  {
    path: '/en/video/playlist/:pid',
    name: 'video-playlist',
    alias: routeHelpers.generateAliasesForRoute('/%s/%s/:pid', ['video', 'playlist']),
    component: () => import(/* webpackChunkName: "page--Video" */ '@/pages/general/Page-VideoPlaylist'),
  },

  // default route
  {
    path: '/',
    redirect: window.defaultLanguage === 'fr' ? `/${window.defaultLanguage}/accueil` : `/${window.defaultLanguage}/home`,
  },
  {
    path: '/en',
    redirect: '/en/home',
  },
  {
    path: '/fr',
    redirect: '/fr/accueil',
  },
];
