export default {

  computed: {
    isCypress() {
      return window.Cypress;
    },
  },

  mounted() {
    /* Cypress scroll hack */
    if (window.Cypress) {
      $(window).scroll(() => {
        // $(window).scrollTop(0);
      });
    }
  },

};
