import { make } from 'vuex-pathify';

const notification = {
  namespaced: true,
  state: {
    unreadNotificationsCount: 0,
    loadingNotifications: false,
    announcements: [],
    notifications: [],
  },
  mutations: {},
  actions: {},
};

const mutations = {};

notification.mutations = Object.assign(make.mutations(notification.state), mutations);

export default notification;
