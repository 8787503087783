import Vue from 'vue';
import { ContentLoader } from 'vue-content-loader';

/* Global Components */
Vue.component('ContentLoader', ContentLoader);
Vue.component('VueHeadful', () => import('vue-headful'));
// Vue.component('CookieLaw', () => import('vue-cookie-law'));

Vue.component('Ad', () => import(/* webpackChunkName: "components--global" */ '@/components/ads/Ad'));
Vue.component('Sticky', () => import(/* webpackChunkName: "components--global" */ '@/components/Sticky'));
// Vue.component('AdPlaceholder', () => import(/* webpackChunkName: "components--global" */ '@/components/ads/AdPlaceholder'));

Vue.component('Wrapper', () => import('@/components/layout/Wrapper'));
Vue.component('Layout', () => import(/* webpackChunkName: "components--layout" */ '@/components/layout/Layout'));
Vue.component('Modal', () => import(/* webpackChunkName: "components--global" */ '@/components/PocModal'));
Vue.component('WithSidebar', () => import(/* webpackChunkName: "components--global" */ '@/components/layout/WithSidebar'));
Vue.component('WithoutSidebar', () => import(/* webpackChunkName: "components--global" */ '@/components/layout/WithoutSidebar'));
Vue.component('Debug', () => import(/* webpackChunkName: "components--global" */ '@/components/Debug'));
Vue.component('Spinner', () => import(/* webpackChunkName: "components--global" */ '@/components/Spinner'));
Vue.component('Popover', () => import(/* webpackChunkName: "components--global" */ '@/components/Popover'));
Vue.component('GoogleReCaptchaV3', () => import(/* webpackChunkName: "components--global" */ '@/components/GoogleReCaptchaV3'));
Vue.component('SeasonSwitcher', () => import(/* webpackChunkName: "components--global" */ '@/components/SeasonSwitcher'));

Vue.component('TableServer', () => import(/* webpackChunkName: "components--tables" */ '@/components/tables/TableServer'));
Vue.component('TableClient', () => import(/* webpackChunkName: "components--tables" */ '@/components/tables/TableClient'));

// Vue.component('TextDropdown', () => import(/* webpackChunkName: "components--layout" */ '@/components/buttons/TextDropdown'));
// Vue.component('ButtonDropdown', () => import(/* webpackChunkName: "components--layout" */ '@/components/buttons/ButtonDropdown'));

Vue.component('NavItemDropdown', () => import(/* webpackChunkName: "components--layout" */ '@/components/layout/nav/NavItemDropdown.vue'));
Vue.component('NavItemSubDropdown', () => import(/* webpackChunkName: "components--layout" */ '@/components/layout/nav/NavItemSubDropdown.vue'));

Vue.component('FormErrors', () => import(/* webpackChunkName: "components--forms" */ '@/components/forms/FormErrors'));
