import routeHelpers from '@/routes/routeHelpers';

const routeRegex = {
  chatroom: ':chatid([0-9]+)',
};

/* THIS IS PURPOSEFULLY COMMENTED OUT, DO NOT REMOVE */
// this.$i18n.t('JSON.ROUTE_login'),
// this.$i18n.t('JSON.ROUTE_register'),
// this.$i18n.t('JSON.ROUTE_forgotten-username'),
// this.$i18n.t('JSON.ROUTE_forgotten-password'),
// this.$i18n.t('JSON.ROUTE_chat'),
// this.$i18n.t('JSON.ROUTE_dashboard'),

const authRoutes = [
  {
    path: '/en/login',
    name: 'login',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['login']),
    component: () => import(/* webpackChunkName: "page--Login" */ '@/pages/utility/Page-Login'),
  },
  {
    path: '/en/register',
    name: 'register',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['register']),
    component: () => import(/* webpackChunkName: "page--Register" */ '@/pages/utility/Page-Register'),
  },
  {
    path: '/en/forgotten-username',
    name: 'forgotten-username',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['forgotten-username']),
    component: () => import(/* webpackChunkName: "page--ForgottenUsername" */ '@/pages/utility/Page-ForgottenUsername'),
  },
  {
    path: '/en/forgotten-password',
    name: 'forgotten-password',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['forgotten-password']),
    component: () => import(/* webpackChunkName: "page--ForgottenPassword" */ '@/pages/utility/Page-ForgottenPassword'),
  },
  {
    path: '/en/chat',
    name: 'chat',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['chat']),
    component: () => import(/* webpackChunkName: "page--Chat" */ '@/pages/chat/Page-Chat'),
    meta: {
      auth: true,
    },
  },
  {
    path: `/en/chat/${routeRegex.chatroom}`,
    name: 'chatroom',
    alias: routeHelpers.generateAliasesForRoute(`/%s/${routeRegex.chatroom}`, ['chat']),
    component: () => import(/* webpackChunkName: "page--Chat" */ '@/pages/chat/Page-Chat'),
    meta: {
      auth: true,
    },
  },
  // authenticated routes
  {
    path: '/en/dashboard',
    name: 'dashboard',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['dashboard']),
    component: () => import(/* webpackChunkName: "page--Dashboard" */ '@/pages/auth/Page-Dashboard'),
    meta: {
      auth: true,
    },
  },
];

if (process.env.NODE_ENV === 'development') {
  authRoutes.push({
    path: '/login/mock',
    component: () => import(/* webpackChunkName: "page--MockLogin" */ '@/pages/utility/Page-MockLogin'),
  });
}

export default authRoutes;
