/* eslint-disable no-param-reassign */

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames } from '@sentry/integrations';

import router from '../router';

if (process.env.NODE_ENV !== 'development') {
  window.Sentry = Sentry;

  const integrations = [];

  integrations.push(new Integrations.BrowserTracing({
    routingInstrumentation: window.Sentry.vueRouterInstrumentation(router),
    tracingOrigins: ['localhost', 'classementpoc.com', /^\//],
  }));

  if (process.env.CORDOVA_PLATFORM) {
    integrations.push(new RewriteFrames(
      {
      // function that takes the frame, applies a transformation, and returns it
        iteratee: (frame) => {
          frame.filename = `~/js/${frame.filename.substring(frame.filename.lastIndexOf('/') + 1)}`;
          return frame;
        },
      },
    ));
  }

  window.Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
    release: `${process.env.PACKAGE_NAME}@${process.env.PACKAGE_VERSION}`,
    integrations,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
