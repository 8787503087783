<template>
  <div class="input-group">
    <multiselect
      v-model="selected"
      label="n"
      track-by="n"
      :placeholder="placeholder"
      open-direction="bottom"
      :options="results"
      :searchable="true"
      :show-labels="false"
      :loading="isLoading"
      :options-limit="options.limit"
      :internal-search="false"
      :custom-label="customLabel"
      selected-label=""
      select-label=""
      deselect-label="X"
      data-lpignore="true"
      @search-change="asyncFindApi"
      @select="onSelect"
      @open="openSearch"
    >
      <template slot="noResult">
        {{ options.noResult }}
      </template>
      <span
        v-if="options.searchIconVisible"
        slot="caret"
        class="icon-search"
      >
        <i
          class="fas fa-search"
          aria-hidden="true"
        />
      </span>

      <!-- pass through scoped slots -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot
          :name="scopedSlotName"
          v-bind="slotData"
        />
      </template>

      <!-- pass through normal slots -->
      <template
        v-for="(_, slotName) in $slots"
        #[slotName]
      >
        <slot :name="slotName" />
      </template>

      <!-- after iterating over slots and scopedSlots, you can customize them like this -->
      <template #overrideExample>
        <slot name="overrideExample" />
        <span>This text content goes to overrideExample slot</span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect';

import debounce from '@/mixins/debounce';

export default {
  components: {
    multiselect,
  },

  mixins: [
    debounce, //
  ],

  props: {
    api: {
      type: String,
      default: () => null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    customLabel: {
      type: Function,
      default: (option) => `${option.id}`,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },

  data() {
    return {
      selected: null,
      results: [],
      isLoading: false,

      defaultOptions: {
        placeholder: 'Search',
        limit: 10,
        minimumSearchQuery: 3,
        searchIconVisible: true,
        allowEmptySearch: false,
      },
    };
  },

  computed: {
    options() {
      return Object.assign(this.defaultOptions, this.config);
    },
  },

  methods: {
    onSelect(option) {
      // console.log('onselect.option', option);
      this.$emit('onSelect', option);
    },

    openSearch() {
      this.results = [];

      if (this.options.allowEmptySearch) {
        this.asyncFindApi('');
      }

      $('.multiselect input[type=text]').each((index, element) => {
        $(element).attr('data-lpignore', true); // prevent lastpass autocomplete
      });
    },

    asyncFindApi(query) {
      this.debounce(() => {
        if (query !== '') {
          this.results = [];

          this.$http.post(this.api, {
            query,
          }).then((res) => {
            this.results = res.data;
          });
        }
      }, 200);
    },
  },
};
</script>

<style>
.multiselect__loading-enter-active,
.multiselect__loading-enter,
.multiselect,
.multiselect__input,
.multiselect__single {
  @apply tw-border-0 tw-outline-none tw-ring-0 !important;
}
</style>
