/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint func-names: ["error", "never"] */

import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import axios from 'axios';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import createPersistedState from 'vuex-persistedstate';

import VuexORMSearch from '@vuex-orm/plugin-search';
import database from './orm/database';

import * as getters from './getters';
import { state, mutations } from './mutations';

// import admin from './modules/admin/Admin';
// import adminFilter from './modules/admin/Filter';
// import adminLog from './modules/admin/Log';

// import category from './modules/models/Category';
// import division from './modules/models/Division';
// import game from './modules/models/Game';
// import league from './modules/models/League';
// import region from './modules/models/Region';
// import season from './modules/models/Season';
// import team from './modules/models/Team';
// import place from './modules/models/Place';
// import tournament from './modules/models/Tournament';
// import user from './modules/models/User';
// import supervisor from './modules/models/Supervisor';
// import video from './modules/models/Video';
// import city from './modules/models/City';
// import organization from './modules/models/Organization';

// import dashboard from './modules/Dashboard';
// import following from './modules/Following';
// import nav from './modules/Nav';
// import navigation from './modules/Navigation';
// import forum from './modules/Forum';

// import captcha from './modules/Captcha';
// import device from './modules/Device';
// import filter from './modules/Filter';
// import general from './modules/General';
// import layout from './modules/Layout';
// import meta from './modules/Meta';
// import search from './modules/Search';
// import orm from './modules/Orm';
// import site from './modules/Site';
// import map from './modules/Map';
// import modal from './modules/Modal';
// import cache from './modules/Cache';
// import lang from './modules/Lang';
// import chat from './modules/Chat';
// import notification from './modules/Notification';
// import stats from './modules/Stats';
// import messages from './modules/Messages';

import modulesAdmin from './modules/admin';
import modulesModels from './modules/models';
import modules from './modules';

Vue.use(Vuex);

VuexORM.use(VuexORMAxios, { axios });

VuexORM.use(VuexORMSearch, {
  // Configure default fuse.js options here (see "Configuration" section below).
});

// import * as actions from './actions';
// import * as mutations from './mutations';

const store = new Vuex.Store({
  plugins: [
    pathify.plugin,
    VuexORM.install(database),
    createPersistedState({
      paths: [
        /* orm */
        // 'entities.chatroom.data',
        // 'entities.message.data',
        'entities.video.data',
        'entities.videoTag.data',
        /* admin modules */
        // 'adminLog',
        // 'adminFilter',
        'entities.tag.data',
        /* modules */
        'captcha',
        'filter',
        'site',
        'map',
        'cache',
        // 'stats',
        'lang',
      ],
    }),
  ],

  state,
  mutations,
  getters,

  modules: {
    // admin,
    // adminFilter,
    // adminLog,

    // category,
    // division,
    // game,
    // league,
    // region,
    // season,
    // team,
    // place,
    // tournament,
    // user,
    // supervisor,
    // video,
    // city,
    // organization,

    ...modulesAdmin,
    ...modulesModels,
    ...modules,

    // dashboard,
    // following,
    // nav,
    // navigation,
    // forum,

    // captcha,
    // device,
    // filter,
    // general,
    // layout,
    // meta,
    // search,
    // orm,
    // site,
    // map,
    // modal,
    // cache,
    // lang,
    // chat,
    // notification,
    // stats,
    // messages,
  },
});

export default store;
