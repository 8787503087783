import {
  Model,
} from '@vuex-orm/core';

import Chatroom from '@/vuex/orm/models/Chatroom';

export default class extends Model {
  static entity = 'feedRule'

  static fields() {
    return {
      id: this.number(0),
      chatroomId: this.number(0),
      user: this.attr({}).nullable(),
      timeAgo: this.string('').nullable(),
      message: this.string('').nullable(),
      chatroom: this.belongsTo(Chatroom, 'chatroomId'),
    };
  }
}
