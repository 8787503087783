import Vapi from 'vuex-rest-api';

const team = new Vapi({
  state: {
    team: {
      id: 0,
      name: '',
      region: {
        name: '',
      },
      category: {
        name: '',
      },
      tournaments: [],
      supervisors: [],
      chart: {
        labels: [],
        datasets: '',
        options: {},
      },
    },

    teamLiked: {
      status: false,
    },

    teams: {
      categories: [

      ],
      count: 0,
    },

    rivals: [
      {
        id: 0,
        name: '',
      },
      {
        id: 0,
        name: '',
      },
    ],

    stats: {
      team: {
        id: 0,
        name: '',
      },

      streaks: {
        winning: {
          longest: null,
          current: null,
          all: [],
        },
        losing: {
          longest: null,
          current: null,
          all: [],
        },
      },

      feats: {},
    },
  },
})
  .get({
    action: 'getTeamList',
    property: 'teams',
    path: ({ sid, fcid }) => `/api/v1/teams/list?sid=${sid}&fcid=${fcid}`,
  })
  .get({
    action: 'getTeam',
    property: 'team',
    path: ({ lang, eid, sid }) => `/api/v1/teams/${eid}?lang=${lang}&sid=${sid}`,
  })
  .get({
    action: 'getTeamStats',
    property: 'team',
    path: ({ lang, eid }) => `/api/v1/teams/${eid}/stats?lang=${lang}`,
  })
  .get({
    action: 'getRivals',
    property: 'rivals',
    path: ({
      lang, sid, eid1, eid2,
    }) => `/api/v1/teams/rivals?lang=${lang}&sid=${sid}&eid1=${eid1}&eid2=${eid2}`,
  })
  .get({
    action: 'getTeamLiked',
    property: 'teamLiked',
    path: ({ eid }) => `/api/v1/teams/${eid}/liked`,
  })
  .getStore();

export default team;
