import i18n from '@/lang/lang';

export default [
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "page--Logout" */ '@/pages/utility/Page-Logout'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "page--Redirect" */ '@/pages/utility/Page-Redirect'),
  },
  {
    path: '/email/*',
    name: 'email',
    component: () => import(/* webpackChunkName: "page--EmailTracker" */ '@/pages/utility/Page-EmailTracker'),
  },
  {
    path: '/callback',
    name: 'oaut2-callback',
    component: () => import(/* webpackChunkName: "page--Oauth2" */ '@/pages/utility/Page-Oauth2'),
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "page--404" */ '@/pages/utility/Page-404'),
    meta: {
      title: `404 ${i18n.t('JSON.page-not-found')}`,
    },
  },
];
