import Vapi from 'vuex-rest-api';

const league = new Vapi({
  state: {
    league: {
      id: 0,
      name: '',
      category: {},
      region: {},
      teams: [],
      chart: {
        labels: [],
        datasets: '',
        options: {},
      },
    },
    leagueLiked: {
      status: false,
    },
    leagueSupervised: {
      status: false,
    },
  },
})
  .get({
    action: 'getLeague',
    property: 'league',
    path: ({
      lang, sid, cid, rid, did,
    }) => `/api/v1/league?lang=${lang}&sid=${sid}&cid=${cid}&rid=${rid}&did=${did}`,
  })
  .get({
    action: 'getLeagueLiked',
    property: 'leagueLiked',
    path: ({ sid, cid, rid }) => `/api/v1/league/liked?sid=${sid}&cid=${cid}&rid=${rid}`,
  })
  .get({
    action: 'getLeagueSupervised',
    property: 'leagueSupervised',
    path: ({ sid, cid, rid }) => `/api/v1/league/supervised?sid=${sid}&cid=${cid}&rid=${rid}`,
  })
  .getStore();

export default league;
