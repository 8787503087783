import Vapi from 'vuex-rest-api';

const tournament = new Vapi({
  state: {
    tournament: {
      id: 0,
      name: '',
      teamsCount: 0,
      tournamentCategories: [],
      chart: {
        labels: [],
        datasets: '',
        options: {},
      },
    },
    tournamentLiked: {
      status: false,
    },
    tournamentSupervised: {
      status: false,
    },
  },
})
  .get({
    action: 'getTournament',
    property: 'tournament',
    path: ({ lang, tid }) => `/api/v1/tournaments/${tid}?lang=${lang}`,
  })
  .get({
    action: 'getTournamentLiked',
    property: 'tournamentLiked',
    path: ({ tid }) => `/api/v1/tournaments/${tid}/liked`,
  })
  .get({
    action: 'getTournamentSupervised',
    property: 'tournamentSupervised',
    path: ({ lang, tid }) => `/api/v1/tournaments/${tid}/supervised?lang=${lang}`,
  })
  .getStore();

export default tournament;
