import Vapi from 'vuex-rest-api';

const following = new Vapi({
  state: {
    following: {
      teams: [],
      tournaments: [],
      leagues: [],
    },
  },
})
  .get({
    action: 'getFollowing',
    property: 'following',
    path: ({ sid }) => `/api/v1/following?sid=${sid}`,
  })
  .getStore();

export default following;
