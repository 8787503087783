<template>
  <div v-if="!isCypress && !isDev() && locked">
    <UnderConstruction
      :title="'Under Construction'"
      :back-button="'back'"
      :show-button="'show'"
      :hide-button="'hide'"
      :show-loader="true"
      :total-digits="4"
      :redirect-url="'/'"
    />
  </div>

  <div v-else>
    <template v-if="isSplash">
      <Splash />
    </template>

    <template v-else-if="isPlayground">
      <Playground />
    </template>

    <template v-else>
      <Poc v-if="navLoaded" />
    </template>
  </div>
</template>

<script>
import routeWatchers from '@/mixins/route-watchers';
import app from '@/mixins/app';
import nav from '@/mixins/nav';
import consoleLog from '@/mixins/console-log';
import cypress from '@/mixins/cypress';

export default {
  components: {
    Poc: () => import(/* webpackChunkName: "apps--Poc" */ '@/apps/Poc'),
    Splash: () => import(/* webpackChunkName: "apps--Splash" */ '@/apps/Splash'),
    Playground: () => import(/* webpackChunkName: "apps--Playground" */ '@/apps/Playground'),
    UnderConstruction: () => import(/* webpackChunkName: "components--UnderConstruction" */ '@/components/UnderConstruction'),
  },

  mixins: [
    routeWatchers, //
    app,
    nav,
    consoleLog,
    cypress,
  ],
};
</script>
