import Vapi from 'vuex-rest-api';

const category = new Vapi({
  state: {
    category: {
      id: 0,
      name: '',
      description: '',
      regions: [],
      leagues: [],
      chart: {
        labels: [],
        datasets: '',
        options: {},
      },
    },
  },
})
  .get({
    action: 'getCategory',
    property: 'category',
    path: ({ lang, cid }) => `/api/v1/categories/${cid}?lang=${lang}`,
  })
  .getStore();

export default category;
