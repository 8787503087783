import { make } from 'vuex-pathify';

const chat = {
  namespaced: true,
  state: {
    limit: 100,
    activeChatId: 0,
    chatFilter: '',
    unreadChatMessagesCount: 0,
    activePeers: {},
  },
  mutations: {},
  actions: {},
};

const mutations = {};

chat.mutations = Object.assign(make.mutations(chat.state), mutations);

export default chat;
