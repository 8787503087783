export default [
  // {
  //   path: '/play',
  //   component: () => import('@/pages/playground/Page-Playground'),
  //   meta: {
  //     title: 'Play',
  //     description: '',
  //   },
  // },

  // {
  //   path: '/play/debug',
  //   component: () => import('@/pages/playground/Page-Debug'),
  //   meta: {
  //     title: 'Play Debug',
  //     description: '',
  //   },
  // },

  {
    path: '/play/sticky',
    component: () => import('@/pages/playground/Page-Sticky'),
    meta: {
      title: 'Play Sticky',
      description: '',
      playground: true,
    },
  },
];
