import Vapi from 'vuex-rest-api';

const user = new Vapi({
  state: {
    user: {
      id: 0,
      username: '',
    },
  },
})
  .get({
    action: 'getUser',
    property: 'user',
    path: ({ lang, uid }) => `/api/v1/users/${uid}?lang=${lang}`,
  })
  .getStore();

export default user;
