import routeHelpers from '@/routes/routeHelpers';

export default [
  {
    path: '/en/facebook',
    name: 'facebook',
    alias: routeHelpers.generateAliasesForRoute('/%s', ['facebook']),
    component: () => import(/* webpackChunkName: "page--Facebook" */ '@/pages/test/Page-Facebook'),
  },
];
