import { make } from 'vuex-pathify';

const map = {
  namespaced: true,
  state: {
    map: {
      zoom: 4,
      center: {
        lat: 45.5,
        lng: -73.5,
      },
    },
  },
  mutations: {},
  actions: {},
};

map.mutations = make.mutations(map.state);

export default map;
