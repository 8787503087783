/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint func-names: ["error", "never"] */

export const state = {
  loading: false,
  pageOptions: {},
  counts: {
    total_tournaments: 0,
    supervised_tournaments: 0,
    total_leagues: 0,
    supervised_leagues: 0,
  },
};

export const mutations = {
  'leaders/LOADING': function (state) {
    state.loading = true;
  },
  'leaders/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'games/LOADING': function (state) {
    state.loading = true;
  },
  'games/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'teams/LOADING': function (state) {
    state.loading = true;
  },
  'teams/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'tournaments/LOADING': function (state) {
    state.loading = true;
  },
  'tournaments/LOADED': function (state, data) {
    // console.log('store.tournaments.loaded', data);
    state.counts.total_tournaments = data.data.count;
    state.counts.supervised_tournaments = data.data.tournamentsSupervisedCount;

    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'leagues/LOADING': function (state) {
    state.loading = true;
  },
  'leagues/LOADED': function (state, data) {
    // console.log('store.leagues.loaded', data);
    state.counts.total_leagues = data.data.count;
    state.counts.supervised_leagues = data.data.leaguesSupervisedCount;

    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'supervisors/LOADING': function (state) {
    state.loading = true;
  },
  'supervisors/LOADED': function (state, data) {
    // console.log('store.tournaments.loaded', data);
    state.counts.total_leagues = data.data.totalLeaguesCount;
    state.counts.supervised_leagues = data.data.leaguesSupervisedCount;
    state.counts.total_tournaments = data.data.totalTournamentsCount;
    state.counts.supervised_tournaments = data.data.tournamentsSupervisedCount;

    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'seasons/LOADING': function (state) {
    state.loading = true;
  },
  'seasons/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'divisions/LOADING': function (state) {
    state.loading = true;
  },
  'divisions/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'categories/LOADING': function (state) {
    state.loading = true;
  },
  'categories/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'regions/LOADING': function (state) {
    state.loading = true;
  },
  'regions/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  'tournamentTeams/LOADING': function (state) {
    state.loading = true;
  },
  'tournamentTeams/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  },

  // 'searchResults/FILTER': function (state, data) {
  //   // console.log('store.searchResults.filter', data);
  // },
  // 'searchResults/PAGINATION': function (state, data) {
  //   // console.log('store.searchResults.pagination', data);
  //   // window.history.replaceState(null, null, `${window.location.pathname}?page=${data}`);
  //   // this.state.loading = true;
  // },

  'adminMembers/LOADING': function (state) {
    state.loading = true;
  },
  'adminMembers/LOADED': function (state) {
    setTimeout(() => {
      state.loading = false;
    }, 1000);
    // console.log('store.adminMembers.loaded', data);
  },
  'adminMembers/FILTER': function (/* data, filter */) {
    // console.log('store.adminMembers.filter', data, filter);
    // this.dispatch('updateSearchText', filter);
  },
  'adminMembers/PAGINATION': function (/* state, data */) {
    // console.log('store.adminMembers.pagination', data);
    // window.history.replaceState(null, null, `${window.location.pathname}?page=${data}`);
    // this.state.loading = true;
  },
};
