import Vue from 'vue';

Vue.filter('shorten', (str, maxLength) => {
  let shortenedStr = str;

  if (shortenedStr.length > maxLength) {
    shortenedStr = shortenedStr.substr(0, maxLength);
    shortenedStr = shortenedStr.substr(0, Math.min(shortenedStr.length, shortenedStr.lastIndexOf(' ')));
    shortenedStr += ' ...';
  }

  return shortenedStr;
});

Vue.filter('slugify', (value) => {
  let slug = value.replace(/^\s+|\s+$/g, ''); // trim
  slug = slug.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;\'';
  const to = 'aaaaaeeeeeiiiiooooouuuunc-------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  slug = slug
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return slug;
});

Vue.filter('unslug', (value) => {
  // console.log(value);
  const stripped = value.replace(/[a-zA-Z]-[0-9]+-/, '');
  // console.log(stripped);
  const words = stripped.split(/[-_]+/g);
  const results = [];
  for (let i = 0; i < words.length; i += 1) {
    const letter = words[i].charAt(0).toUpperCase();
    results.push(letter + words[i].slice(1));
  }
  return results.join(' ');
});

Vue.filter('extractId', (value) => {
  const params = value.split(/[-]+/g);
  return parseInt(params[1], 10);
});

Vue.filter('extractLang', (path, allowedLanguages, defaultLang) => {
  const params = path.split(/[/]+/g);
  return allowedLanguages.indexOf(params[1]) > -1 ? params[1] : defaultLang;
});

Vue.filter('ucfirst', (value) => value.charAt(0).toUpperCase() + value.slice(1));

Vue.filter('hyphenToSentence', value => _.startCase(_.toLower(value)).replace('-', ' '));

Vue.filter('charFilter', (str, length) => ((typeof str !== 'undefined') ? str.substring(0, length) : 'n/a'));
