/* eslint-disable default-case */

import i18n from '@/lang/lang';

const routeRegex = {
  season: ':r_season(\\d{4}-\\d{4})', // matches /2014-2015
};

const defaultLanguage = 'fr';

export default [

  // DONE
  // /saison2018-2019 // season
  // /saison2018-2019/equipes_liste.php // all teams
  // /saison2018-2019/equipes.php // all teams
  // /saison2018-2019/equipes.php?ClassID=34&RegionID=30&HomeID=2566 // team
  // /saison2018-2019/rivalites.php?HomeID1=2566&HomeID2=2573 // team rivalries
  // /saison2018-2019/categories.php?ClassID=7&RegionID=38 // league
  // /saison2018-2019/categories.php?ClassID=8 // category
  // /saison2018-2019/participez.php?show=tournois // all tournaments
  // /saison2018-2019/categories.php?ClassID=8&display=play // tournaments

  // NOT DONE

  {
    path: `/saison${routeRegex.season}/categories.php`,
    name: 'redirect',
    beforeEnter: (to, from, next) => {
      let redirect;

      switch (true) {
        case parseInt(to.query.ClassID, 10) > 0:
          axios
            .post('/api/v1/redirect/category', {
              sid: to.params.r_season,
              ...to.query,
            })
            .then((response) => {
              redirect = response.data;
              // console.log(`redirecting to /${defaultLanguage}${redirect}`);
              next({ path: `/${defaultLanguage}${redirect}`, query: {} });
            }).catch((/* err */) => {
              // console.log('redirect.err', err.response);
              redirect = `/${to.params.r_season}`;
              next({ path: `/${defaultLanguage}${redirect}`, query: {} });
            });
          break;
      }
    },
  },

  {
    path: `/saison${routeRegex.season}/equipes.php`,
    name: 'redirect',
    beforeEnter: (to, from, next) => {
      let redirect;

      // console.log('redirect.route.to', to);

      switch (true) {
        case parseInt(to.query.HomeID, 10) > 0:
          axios
            .post('/api/v1/redirect/team', {
              sid: to.params.r_season,
              ...to.query,
            })
            .then((response) => {
              redirect = response.data;
              // console.log(`redirecting to /${defaultLanguage}${redirect}`);
              next({ path: `/${defaultLanguage}${redirect}`, query: {} });
            }).catch((/* err */) => {
              // console.log('redirect.err', err.response);
              redirect = `/${to.params.r_season}`;
              next({ path: `/${defaultLanguage}${redirect}`, query: {} });
            });
          break;
      }
    },
  },

  {
    path: `/saison${routeRegex.season}/rivalites.php`,
    name: 'redirect',
    beforeEnter: (to, from, next) => {
      let redirect;

      // console.log('redirect.route.to', to);

      switch (true) {
        case parseInt(to.query.HomeID1, 10) > 0 && parseInt(to.query.HomeID2, 10) > 0:
          axios
            .post('/api/v1/redirect/rivals', {
              sid: to.params.r_season,
              ...to.query,
            })
            .then((response) => {
              redirect = response.data;
              // console.log(`redirecting to /${defaultLanguage}${redirect}`);
              next({ path: `/${defaultLanguage}${redirect}`, query: {} });
            }).catch((/* err */) => {
              // console.log('redirect.err', err.response);
              redirect = `/${to.params.r_season}`;
              next({ path: `/${defaultLanguage}${redirect}`, query: {} });
            });
          break;
      }
    },
  },

  {
    path: `/saison${routeRegex.season}/participez.php`,
    name: 'redirect',
    redirect(route) {
      let redirect;
      switch (true) {
        case route.query.show === 'tournois':
          redirect = `/${route.params.r_season}/${i18n.t('JSON.ROUTE_tournaments')}`;
          break;

        default:
          redirect = `/${route.params.r_season}`;
      }
      // console.log(`redirecting to /${defaultLanguage}${redirect}`);

      return { path: `/${defaultLanguage}${redirect}`, query: {} };
    },
  },

  {
    path: `/saison${routeRegex.season}/superviseurs.php`,
    name: 'redirect',
    redirect: `/${defaultLanguage}/${routeRegex.season}/${i18n.t('JSON.ROUTE_supervisors')}`,
  },
  {
    path: `/saison${routeRegex.season}/equipes_liste.php`,
    name: 'redirect',
    redirect: `/${defaultLanguage}/${routeRegex.season}/${i18n.t('JSON.ROUTE_teams')}`,
  },
  {
    path: `/saison${routeRegex.season}`,
    name: 'redirect',
    redirect: `/${defaultLanguage}/${routeRegex.season}`,
  },

  {
    path: '/participez.php',
    name: 'redirect',
    redirect: `/${defaultLanguage}/${i18n.t('JSON.ROUTE_participate')}`,
  },
  {
    path: '/faq.php',
    name: 'redirect',
    redirect: `/${defaultLanguage}/${i18n.t('JSON.ROUTE_faq')}`,
  },
  {
    path: '/contacts.php',
    name: 'redirect',
    redirect: `/${defaultLanguage}/${i18n.t('JSON.ROUTE_contact')}`,
  },
  {
    path: '/hockey/a-propos',
    name: 'redirect',
    redirect: `/${defaultLanguage}/${i18n.t('JSON.ROUTE_about')}`,
  },
];
