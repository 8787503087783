import Vapi from 'vuex-rest-api';

const forum = new Vapi({
  state: {
    posts: [],
  },
})
  .get({
    action: 'getPosts',
    property: 'posts',
    path: () => '/api/v1/forum/posts',
  })
  .getStore();

export default forum;
