import { mapState, mapActions } from 'vuex';
import { sync } from 'vuex-pathify';

export default {

  data() {
    return {
      navLoaded: false,
    };
  },

  computed: {
    ...sync([
      'general/currentSeason', //
      'general/menu',
    ]),

    ...mapState({
      nav: state => state.nav.nav,
    }),
  },

  methods: {

    ...mapActions([
      'getNav', //
    ]),

    async loadNav() {
      // console.log(`app.loadNav... currentSeason:${this.currentSeason}`);

      if (typeof this.nav[this.currentSeason] === 'undefined') {
        // console.log(`app.loadNav... fetching nav for ${this.currentSeason} from API`);
        await this.getNav({ params: { season: this.currentSeason } })
          .then(() => {
            // console.log('nav loaded');
          })
          .catch((err) => {
            console.log('err', err);
          });
      } else {
        // console.log(`app.loadNav... fetching nav for ${this.currentSeason} from VUEX`);
      }

      this.updateSeasonId();
      this.navLoaded = true;
      this.$store.set('general/menu', this.nav[this.currentSeason]);
    },

    updateSeasonId() {
      // console.log('app.updateSeasonId', this.currentSeason, this.nav);
      const matchedSeasonId = this.nav[this.currentSeason].seasons.find(season => season.name === this.currentSeason).id;
      this.$store.set('general/currentSeasonId', matchedSeasonId);
    },

  },
};
