import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

import router from '../router';

Vue.use(VueAnalytics, {
  id: 'UA-2596233-1',
  router,
  autoTracking: {
    //   page: true,
    //   screenview: false,
    //   exception: true,
    //   pageviewOnLoad: true,
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href,
      };
    },
  },
// debug: {
//   enabled: !isProd,
//   trace: !isProd,
//   sendHitTask: !isProd,
// },
});
