/* eslint-disable no-param-reassign */

import Vapi from 'vuex-rest-api';

const adminLog = new Vapi({
  state: {
    adminLogs: {
      // Custom Log Levels
      queueLog: [],
      jobLog: [],
      cacheLog: [],

      teamLog: [],
      gameLog: [],
      tournamentLog: [],

      // Internal Log Levels
      emergencyLog: [],
      alertLog: [],
      criticalLog: [],
      errorLog: [],
      warningLog: [],
      noticeLog: [],
      infoLog: [],
      debugLog: [],
    },
  },
})
  .get({
    action: 'getAdminLog',
    path: ({ channel }) => `/api/v1/admin/logs?channel=${channel}`,
    onSuccess: (state, payload, axios, { params }) => {
      state.adminLogs[params.channel] = payload.data;
    },
  })
  .get({
    action: 'getAdminLogs',
    property: 'adminLogs',
    path: () => '/api/v1/admin/logs',
  })
  .getStore();

const mutations = {
  appendToLog: (state, payload) => {
    // console.log(payload);
    state.adminLogs[payload.channel].push(payload);
  },
  populateLog: (state, payload) => {
    // console.log(payload);
    state.adminLogs[payload.channel] = payload;
  },
  clearLog: (state, payload) => {
    // console.log(payload);
    state.adminLogs[payload.channel] = [];
  },
};

const actions = {
  appendToLog({ commit }, payload) {
    commit('appendToLog', payload);
  },
  populateLog({ commit }, payload) {
    commit('populateLog', payload);
  },
  clearLog({ commit }, payload) {
    commit('clearLog', payload);
  },
};

adminLog.mutations = Object.assign(adminLog.mutations, mutations);
adminLog.actions = Object.assign(adminLog.actions, actions);

export default adminLog;
