import Vapi from 'vuex-rest-api';

const place = new Vapi({
  state: {
    teamPlaces: {},
    tournamentPlaces: {},
  },
})
  .get({
    action: 'getTeamPlaces',
    property: 'teamPlaces',
    path: ({ sid, cid }) => {
      if (cid) {
        return `/api/v1/places/teams?sid=${sid}&cid=${cid}`;
      }

      return `/api/v1/places/teams?sid=${sid}`;
    },
  })
  .get({
    action: 'getTournamentPlaces',
    property: 'tournamentPlaces',
    path: ({ sid, cid }) => `/api/v1/places/tournaments?sid=${sid}&cid=${cid}`,
  })
  .getStore();

export default place;
