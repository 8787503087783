/* eslint-disable import/prefer-default-export */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // Optional

import Vue from 'vue';

import 'bootstrap';

import BootstrapVue from 'bootstrap-vue';
import device from 'vue-device-detector';
import Notifications from 'vue-notification';
import Sticky from 'vue-sticky-directive';
import ToggleButton from 'vue-js-toggle-button';
import VuePlyr from 'vue-plyr';
import VueSweetalert2 from 'vue-sweetalert2';
import vuetimeline from '@growthbunker/vuetimeline';
import vClickOutside from 'v-click-outside';

import '@/config';
import App from '@/App.vue';
import i18n from '@/lang/lang';
import router from '@/router';
import store from '@/vuex/store';
import '@/auth';
import '@/registerServiceWorker';
import '@/mixins/filters';

// import '@/plugins/fontawesome';
import '@/plugins/vue-composition-api';
// import '@/plugins/ray';
import '@/plugins/v-calendar';
import '@/plugins/vue-analytics';
// import '@/plugins/vue-dfp';
// import '@/plugins/vue-adsense';
// import '@/plugins/vue-disqus';
// import '@/plugins/vue-lazy';
// import '@/plugins/vue-matomo';
// import '@/plugins/vue-socialsharing';
import '@/plugins/vue-timeago';
import '@/plugins/vue2-google-maps';
import '@/plugins/websockets';
import '@/plugins/sentry';

import '@/global/components';
import '@/global/components-utility';
import '@/global/mixins';

import '@/assets/css/tailwind.css';

Vue.use(BootstrapVue);
Vue.use(device);
Vue.use(Notifications);
Vue.use(Sticky);
Vue.use(ToggleButton);
Vue.use(VuePlyr);
Vue.use(VueSweetalert2);
Vue.use(vuetimeline);
Vue.use(vClickOutside);

// const isProd = true;

window.Bus = new Vue();

Vue.config.productionTip = false;

export const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  computed: {
    isAuthenticated() {
      // console.log('main.computed.isAuthenticated', this.$auth.check());
      return this.$auth.check();
    },
  },
  watch: {
    isAuthenticated() {
      // console.log('main.watch.isAuthenticated', this.$auth.token());
      window.Echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${this.$auth.token()}`;
    },
  },
  render: h => h(App),
});
