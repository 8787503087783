import { make } from 'vuex-pathify';

const modal = {
  namespaced: true,
  state: {
    submittedContent: {},
    debug: {},
  },
  mutations: {},
  actions: {},
};

modal.mutations = make.mutations(modal.state);

export default modal;
