import {
  Model,
} from '@vuex-orm/core';

import Video from './Video';
import VideoTag from './VideoTag';

export default class extends Model {
  static entity = 'tag'

  static primaryKey = 'slug'

  static fields() {
    return {
      // id: this.number(0),
      name: this.string(''),
      slug: this.string(''),
      type: this.string(''),
      extra: this.attr({}),
      videos: this.belongsToMany(Video, VideoTag, 'slug', 'pid'),
    };
  }
}
