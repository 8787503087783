import { sync } from 'vuex-pathify';

export default {

  computed: {
    ...sync([
      'site/locked', //
    ]),

    isSplash() {
      return window.location.pathname.includes('/splash');
    },

    isPlayground() {
      return window.location.pathname.includes('/playground');
    },
  },
};
