import Vapi from 'vuex-rest-api';

const season = new Vapi({
  state: {
    season: {
      id: 0,
      slug: '',
      regions: [],
      categories: [],
      tournaments: [],
      supervisors: [],
    },
  },
})
  .get({
    action: 'getSeason',
    property: 'season',
    path: ({ seasonSlug }) => `/api/v1/seasons/${seasonSlug}`,
  })
  .getStore();

export default season;
