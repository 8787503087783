<template>
  <span class="tw-text-blue-500">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    datetime: {
      type: String,
      default: () => '',
    },
  },
};
</script>
