import {
  Model,
} from '@vuex-orm/core';

import Tag from './Tag';
import VideoTag from './VideoTag';

export default class extends Model {
  static entity = 'video'

  static primaryKey = 'pid'

  static fields() {
    return {
      pid: this.string(''),
      tags: this.belongsToMany(Tag, VideoTag, 'pid', 'slug'),
      published_at: this.string(''),
      cid: this.string(''),
      channel_title: this.string(''),
      title: this.string(''),
      description: this.string(''),
      duration_str: this.string(''),
      duration_sec: this.number(0),
      view_count: this.number(0),
      like_count: this.number(0),
      dislike_count: this.number(0),
      like_dislike_ratio: this.number(0),
      favorite_count: this.number(0),
      comment_count: this.number(0),
      thumbnail: this.string(''),
      fetched_at: this.string(''),
      added_on: this.string(''),
    };
  }
}
