import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './locale/en.json';
import fr from './locale/fr.json';

Vue.use(VueI18n);

const locale = window.defaultLanguage;

const messages = {
  en,
  fr,
};

const i18n = new VueI18n({
  locale,
  messages,
});

export default i18n;
