/* eslint-disable no-param-reassign */

import Vapi from 'vuex-rest-api';

const admin = new Vapi({
  state: {
    adminGlobalCounts: {
      // Custom Log Levels
      post: 0,
      channel: 0,
      post_ready: 0,
      channel_ready: 0,
      tag: 0,
      feed: 0,
      feedRule: 0,
      job: 0,
      jobItem: 0,
      list: 0,
      listItem: 0,
      network: 0,
      profile: 0,
    },
  },
})
  .get({
    action: 'getAdminGlobalCounts',
    path: () => '/api/v1/admin/globalCounts',
    property: 'adminGlobalCounts',
  })
  .getStore();

const mutations = {
  updateGlobalCount: (state, payload) => {
    console.log(payload);
    state.adminGlobalCounts[payload.model] = payload.count;
  },
  updateGlobalCounts: (state, payload) => {
    // console.log(payload);
    state.adminGlobalCounts = payload;
  },
};

const actions = {
  updateGlobalCount({ commit }, payload) {
    commit('updateGlobalCount', payload);
  },
  updateGlobalCounts({ commit }, payload) {
    commit('updateGlobalCounts', payload);
  },
};

admin.mutations = Object.assign(admin.mutations, mutations);
admin.actions = Object.assign(admin.actions, actions);

export default admin;
