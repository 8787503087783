import Vue from 'vue';

let browserLanguage;
const extractedLang = window.location.pathname.split(/[/]+/g)[1];

if (['fr', 'en'].indexOf(extractedLang) > -1) {
  window.defaultLanguage = extractedLang;
  // console.log(`language is already set to ${extractedLang}`);
} else {
  browserLanguage = (navigator.language || navigator.userLanguage).substring(0, 2);
  window.defaultLanguage = ['en', 'fr'].indexOf(browserLanguage) > -1 ? browserLanguage : 'fr';
  // console.log(`defaulting to lang ${window.defaultLanguage} (browser lang:${browserLanguage})`);
}

Vue.prototype.$is_dev = process.env.NODE_ENV === 'development';
Vue.prototype.$is_app = process.env.CORDOVA_PLATFORM;

switch (true) {
  case window.location.hostname.includes('.test'):
    Vue.prototype.$api = process.env.VUE_APP_API_URL_DEV;
    Vue.prototype.$admin = process.env.VUE_APP_API_URL_DEV;
    Vue.prototype.$metas = process.env.VUE_APP_SITE_URL;
    break;

  case (process.env.NODE_ENV === 'development' && ['android', 'ios'].includes(Vue.prototype.$is_app)):
    Vue.prototype.$api = 'https://61221e6d.ngrok.io';
    Vue.prototype.$admin = process.env.VUE_APP_API_URL_DEV;
    Vue.prototype.$metas = process.env.VUE_APP_SITE_URL;
    break;

  case (process.env.NODE_ENV === 'development'):
    Vue.prototype.$api = '';
    Vue.prototype.$admin = process.env.VUE_APP_API_URL_DEV;
    Vue.prototype.$metas = process.env.VUE_APP_SITE_URL;
    break;

  default:
    Vue.prototype.$api = process.env.VUE_APP_API_URL_PROD;
    Vue.prototype.$admin = process.env.VUE_APP_API_URL_PROD;
    Vue.prototype.$metas = process.env.VUE_APP_SITE_URL;
}

Vue.prototype.$config = {
  features: {

    recaptcha: {
      enabled: process.env.VUE_APP_FEATURES_RECAPTCHA_ENABLED,
    },

    social: {
      slidePanel: {
        enabled: process.env.VUE_APP_FEATURES_SOCIAL_SLIDE_PANEL_ENABLED,
        twitter: process.env.VUE_APP_FEATURES_SOCIAL_TWITTER_SLIDE_PANEL_ENABLED,
        facebook: process.env.VUE_APP_FEATURES_SOCIAL_FACEBOOK_SLIDE_PANEL_ENABLED,
      },
      twitter: {
        url: process.env.VUE_APP_FEATURES_SOCIAL_TWITTER_URL,
        enabled: process.env.VUE_APP_FEATURES_SOCIAL_TWITTER_ENABLED,
      },
      facebook: {
        url: process.env.VUE_APP_FEATURES_SOCIAL_FACEBOOK_URL,
        enabled: process.env.VUE_APP_FEATURES_SOCIAL_FACEBOOK_ENABLED,
      },
    },
  },
};

// console.log('env', process.env);
// console.log('NODE_ENV', process.env.NODE_ENV);
