/* eslint-disable no-param-reassign */

import i18n from '@/lang/lang';

export default class Helpers {
  static generateAliasesForRoute(routePattern, translatableRouteFragments) {
    // for every language other than english, generate an alias

    const languages = [
      'en',
      'fr',
    ];

    const translatedRouteFragments = {};
    const aliases = [];

    $.each(languages, (languageIndex) => {
      translatedRouteFragments[languages[languageIndex]] = [];
      // console.log('lang', languages[languageIndex]);
      $.each(translatableRouteFragments, (fragmentIndex) => {
        // console.log('fragment', translatableRouteFragments[fragmentIndex]);
        // translatedRouteFragments.push(i18n.t(`JSON.ROUTE_${translatableRouteFragments[fragmentIndex]}`, languages[languageIndex]));
        // console.log(languages[languageIndex]);
        translatedRouteFragments[languages[languageIndex]].push(i18n.t(`JSON.ROUTE_${translatableRouteFragments[fragmentIndex]}`, languages[languageIndex]));
        // console.log(translatedRouteFragments);
      });

      aliases.push(`/${languages[languageIndex]}${this.interpolate(routePattern, translatedRouteFragments[languages[languageIndex]])}`);
    });

    // console.log('aliases', aliases);

    return aliases;
  }

  static interpolate(theString, argumentArray) {
    const regex = /%s/;
    const r = (p, c) => p.replace(regex, c);
    return argumentArray.reduce(r, theString);
  }
}
