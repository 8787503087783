import { make } from 'vuex-pathify';

const navigation = {
  namespaced: true,
  state: {
    usersOnline: [],
    usersOnlineUnique: [],
  },
  mutations: {},
  actions: {},
};

navigation.mutations = make.mutations(navigation.state);

export default navigation;
