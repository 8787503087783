import Vapi from 'vuex-rest-api';

const region = new Vapi({
  state: {
    region: {
      id: 0,
      name: '',
      description: '',
    },
  },
})
  .get({
    action: 'getRegion',
    property: 'region',
    path: ({ lang, rid }) => `/api/v1/regions/${rid}?lang=${lang}`,
  })
  .getStore();

export default region;
