import Vue from 'vue';
import pathToRegexp from 'path-to-regexp';

import router from '../router';
import store from '../vuex/store';

/* Global Methods */
Vue.mixin({
  methods: {
    ucfirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    routeTo(path) {
      this.$router.push(this.navigate(path));
    },

    navigate(path) {
      // console.log(path);
      // console.log(store.state.lang.lang);
      const injectedLangPath = `/${store.state.lang.lang}${path}`;
      return injectedLangPath;
    },

    switchLang(lang, instance) {
      // console.log(router.options.routes);
      // console.log(`switchLang -> ${lang}`);
      store.dispatch('lang/switchLang', lang);
      instance.navigateToTranslatedRoute(lang, instance);
    },

    navigateToTranslatedRoute(lang, instance) {
      const routeKeys = [
        { name: 'u_lang' },
        { name: 'u_blog' },
        { name: 'u_category' },
        { name: 'u_city' },
        { name: 'u_game' },
        { name: 'u_organization' },
        { name: 'u_page' },
        { name: 'u_region' },
        { name: 'u_season' },
        { name: 'u_supervisor' },
        { name: 'u_team_1' },
        { name: 'u_team_2' },
        { name: 'u_team' },
        { name: 'u_tournament' },
        { name: 'u_user' },
      ];

      // console.log(`${lang} - navigateToTranslatedRoute.route`, instance.$route);

      const matchedRoutes = router.options.routes.filter((el) => el.name === instance.$route.name);

      const currentRoute = matchedRoutes.filter((matchedRoute) => {
        const currentMatchedRoute = matchedRoute.alias.filter((alias) => {
          const re = pathToRegexp(alias, routeKeys);
          return re.exec(instance.$route.path) !== null;
        });

        return currentMatchedRoute.length > 0;
      })[0];

      // console.log('currentRoute', currentRoute);

      const translatedRoute = currentRoute.alias.filter((el) => {
        const regEx = new RegExp(`^/${lang}/`);
        // const regEx = new RegExp(`^/[a-z]{2}${to.path}$`);
        return regEx.test(el);
      })[0];

      // console.log('translatedRoute', translatedRoute);

      const toPath = pathToRegexp.compile(translatedRoute);

      const translatedPath = toPath(instance.$route.params);

      if (window.location.pathname !== translatedPath) {
        // console.log(`navigating to translated route ${translatedPath}`);
        window.history.replaceState({}, null, translatedPath);
        // instance.setMetasFromPath(translatedPath);
      }
    },

    // setMetasFromPath: (path) => {
    //   // const metaUrl = `${Vue.prototype.$metas}/metas${path}/meta.json`;
    //   const metaUrl = `/api/v1/meta?s=${path}`;
    //   axios
    //     .get(metaUrl)
    //     .then((response) => {
    //       // console.log('meta.response', response.data);
    //       store.dispatch('meta/updateMeta', response.data);
    //     }).catch((err) => {
    //       console.log('metas.err', err.response);
    //     });
    // },

    metaTitle() {
      return store.state.meta.title;
    },

    isLoggedIn() {
      if (this.$auth.check()) {
        return true;
      }

      Bus.$emit('ok-box:show', {
        title: this.$t('JSON.please-log-in'),
        body: this.$t('JSON.log-in-required-for-action'),
        size: 'md',
        buttonSize: 'md',
      });

      return false;
    },

    isWip() {
      return this.$route.query.wip;
    },

    hasPermissionToDelete() {
      return this.$auth.check();
    },

    isTester() {
      return this.$auth.check() && this.$auth.user().roles.includes('tester');
    },

    isSupervisor() {
      return this.$auth.check() && this.$auth.user().roles.includes('supervisor');
    },

    isSpecialSupervisor() {
      return this.$auth.check() && this.$auth.user().roles.includes('special supervisor');
    },

    isAdmin() {
      return this.$auth.check() && this.$auth.user().roles.includes('admin');
    },

    isSuperAdmin() {
      return this.$auth.check() && this.$auth.user().roles.includes('super admin');
    },

    isMasterAdmin() {
      return this.$auth.check() && this.$auth.user().roles.includes('master admin');
    },

    isIos() {
      return process.env.CORDOVA_PLATFORM === 'ios';
    },

    isAndroid() {
      return process.env.CORDOVA_PLATFORM === 'android';
    },

    isApp() {
      return ['android', 'ios'].includes(process.env.CORDOVA_PLATFORM);
    },

    isWeb() {
      return !['android', 'ios'].includes(process.env.CORDOVA_PLATFORM);
    },

    isDev() {
      return process.env.NODE_ENV !== 'production';
    },

    isMobileUA() {
      return /Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    },

    isMobile() {
      return !this.isDesktop();
    },

    isDesktop() {
      return !this.isMobileUA() && !this.isApp();
    },

    openInNova(path) {
      if (typeof path === 'undefined') {
        path = '';
      }
      window.open(`https://api.classementpoc.${this.isDev() ? 'test' : 'com'}/nova${path}?token=${this.$auth.token()}`);
    },

    log(...args) {
      if (this.isSuperAdmin()) {
        console.log(...args);
      }
    },

    ray(...args) {
      if (this.isSuperAdmin()) {
        this.$ray(...args);
      }
    },
  },
});
